@import url("https://fonts.googleapis.com/css?family=IBM+Plex+Sans");

/* Root settings & variables */

:root {
  --clr-primary-1: rgb(253, 253, 253);
  --clr-primary-2: rgb(239, 239, 239);
  --clr-primary-3: rgb(169, 169, 169);
  --clr-primary-4: rgb(106, 106, 106);
  --clr-primary-1-opacity: rgba(253, 253, 253, 0.85);

  --clr-complimentary-1: rgb(237, 28, 36);
  --clr-complimentary-2: rgb(198, 16, 23);
  --clr-complimentary-3: rgb(164, 33, 38);
  --clr-complimentary-3-opacity: rgba(164, 33, 38, 0.2);

  --clr-accent: rgb(255, 242, 0);

  --clr-neutral-dark: rgb(5, 5, 5);

  --ff-primary: -apple-system, BlinkMacSystemFont, "Roboto", "Segoe UI",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  --ff-accent: "IBM Plex Sans";

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Site setup */

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  position: relative;
  min-height: 100%;
}

body {
  font-family: var(--ff-primary);
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.6;
}

body,
h1,
h2,
h3,
h4,
h5,
p {
  margin: 0;
  color: var(--clr-neutral-dark);
}

h1,
h2,
h3 {
  font-family: var(--ff-accent);
  font-weight: 900;
}

/* Layout */

section {
  padding: 1.5rem 0;
}

.wrapper {
  min-height: 100vh;
  display: grid;
  grid-template-rows: 1fr auto;
}

img {
  border-radius: 3px;
  display: block;
  max-width: 100%;
}

/* Header & Nav */

header {
  position: fixed;
  z-index: 999;
  width: 100%;
  background-color: var(--clr-primary-1);
  margin-bottom: 3rem;
}

.header-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  border-bottom: solid 1px var(--clr-primary-2);
}

.hamburger {
  margin-right: 1em;
  display: flex;
  justify-content: flex-end;
}

.logo {
  font-size: 2rem;
  font-family: var(--ff-accent);
  color: var(--clr-complimentary-2);
  margin-left: 1em;
}

nav {
  position: absolute;
  top: 100%;
  width: 100%;
  height: calc(100vh - 100%);
  transform: scale(1, 0);
  transform-origin: top;
  transition: transform 400ms ease-in-out;
}

.nav-mobile {
  transform: scale(1, 1);
}

@media (min-width: 55em) {
  .header-container {
    grid-template-columns: 1fr 1fr;
    width: min(90%, 70.5rem);
    justify-content: space-between;
    margin-inline: auto;
  }

  .logo {
    margin: unset;
  }

  .hamburger {
    display: none;
  }

  nav {
    all: unset;
  }
}

main {
  padding-top: 3em;
}

footer {
  padding: 1em;
  margin-top: 5em;
  box-shadow: 0 0 0 1px var(--clr-complimentary-1);
}

@media (min-width: 55em) {
  footer {
    margin-top: 10em;
  }
}

/* Navbar links */

.nav-links {
  list-style: none;
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: var(--clr-primary-1-opacity);
}

.nav-links a {
  color: var(--clr-neutral-dark);
  text-decoration: none;
  font-size: 2rem;
}

.nav-links a:visited {
  color: var(--clr-neutral-dark);
}

.nav-links a:hover {
  color: var(--clr-primary-3);
}

.active-link {
  color: var(--clr-complimentary-1) !important;
}

.nav-link {
  margin-bottom: 0.5rem;
}

.show-link {
  /* Show links on mobile */
  opacity: 1;
  transition: opacity 250ms ease-in-out 250ms;
}

.hide-link {
  /* Hide links on mobile */
  opacity: 0;
  transition: opacity 150ms ease-in-out;
}

@media (min-width: 55em) {
  /* Always show links on desktop */
  .nav-links {
    display: flex;
    justify-content: flex-end;
  }

  .nav-links a {
    opacity: 1;
    font-size: 1rem;
    padding-left: 1em;
  }

  .nav-link {
    margin-bottom: unset;
  }
}

/* Equal Split */

.split {
  display: grid;
  gap: 2em;
}

@media (min-width: 55em) {
  .split {
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
  }
}

/* 30/70 Split */

@media (min-width: 55em) {
  .split-30-70 {
    grid-template-columns: 3fr 7fr;
    gap: 1em;
  }
}

/* Additional split rules */

.split h2 {
  line-height: 0.7;
  margin-bottom: 0.3rem;
}

/* styled link */

.styled-link {
  position: relative;
  color: var(--clr-complimentary-1);
  text-decoration: none;
}

.styled-link::before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 1px;
  bottom: 0;
  left: 0;
  background-color: var(--clr-complimentary-1);
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.3s ease;
}

.styled-link:hover::before {
  transform: scaleX(1);
}

.yellow-link {
  color: var(--clr-accent);
}

.yellow-link::before {
  background-color: var(--clr-accent);
}

/* Bylaws */

.bylaws > h2 {
  margin-top: 1em;
}

.bylaws > h3 {
  margin-top: 0.5em;
}

/* Helpers */

.text-center {
  text-align: center;
}

.container {
  margin-inline: auto;
  width: min(90%, 80.5rem);
}

@media (min-width: 65em) {
  .container--narrow {
    padding-inline: 15em;
  }
}

.text-accent {
  background-color: var(--clr-accent);
}

.text-red {
  color: var(--clr-complimentary-1);
}

/* LINK DEFAULT STYLE */

.default-link {
  color: var(--clr-complimentary-1);
  text-decoration: none;
}

.default-link:visited {
  color: var(--clr-neutral-dark);
}

.default-link:hover {
  color: var(--clr-primary-3) !important;
}

/* Social link */

.socials {
  display: flex;
  gap: 0.5rem;
}

.social-link {
  font-size: 3rem;
  color: var(--clr-accent) !important;
}

.social-link:visited {
  color: var(--clr-accent) !important;
}

.social-link:hover {
  color: var(--clr-accent) !important;
}

/* spiik-map */

#spiik-map {
  width: 100%;
  height: 30rem;
}

/* Background defaults */

.bg-light {
  background-color: var(--clr-primary-1);
}

.bg-dark {
  background-color: var(--clr-primary-2);
}

.bg-footer {
  background-color: var(--clr-complimentary-1);
}
